
import RecipientsSelector from "@/components/RecipientsSelector"
import Textarea from "@/components/Textarea"
import { SharePermission } from "@evercam/shared/types/shares"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: { Textarea, RecipientsSelector },
  props: {
    selectedProject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emails: "",
      shareAllCameras: true,
      inviteeCamerasAccessValue: [],
      createdShare: {
        permission: "",
        email: "",
        message: "",
        isSavingShares: false,
      },
    }
  },
  computed: {
    ...mapStores(useCameraStore),
    inviteeCamerasAccess: {
      get() {
        if (this.shareAllCameras) {
          return this.cameraExids
        } else {
          return this.inviteeCamerasAccessValue
        }
      },
      set(value) {
        console.log(value, this.inviteeCamerasAccessValue)
        this.inviteeCamerasAccessValue = [...value]
      },
    },
    rights() {
      return [
        {
          text: this.$t("content.shares.create_form.rights_labels.read_only"),
          value: SharePermission.Minimum,
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only_share"
          ),
          value: SharePermission.Share,
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.full"),
          value: SharePermission.Full,
          disabled:
            this.cameraStore.selectedCamera?.rights.indexOf("edit") === -1,
        },
      ]
    },
    cameraExids() {
      return this.selectedProject.cameras.map((camera) => camera.id)
    },
    shareDisabled() {
      return !this.createdShare.email || !this.createdShare.permission
    },
  },
  methods: {
    async save() {
      this.$analytics.saveEvent(AnalyticsEvent.projectMembersAddMember)
      const generateRightList = function (permissions) {
        let rights = ["list", "view", "snapshot"]

        if (permissions === SharePermission.Share) {
          rights = [...rights, "share"]
        } else if (permissions === SharePermission.Full) {
          rights = [...rights, "share", "edit"]
        }

        return rights.join(",")
      }
      try {
        this.isSavingShares = true
        let payload = {
          ...this.createdShare,
          email: this.createdShare.email,
          rights: generateRightList(this.createdShare.permission),
        }
        const cameraExids = this.shareAllCameras
          ? this.cameraExids
          : this.inviteeCamerasAccessValue
        const sharePromises = cameraExids.map((cameraId, index) => {
          payload.notify = index === 0
          EvercamApi.shares.cCreate(cameraId, payload)
        })

        await Promise.all(sharePromises)
        this.$notifications.success(
          "Cameras successfully shared with the users"
        )
      } catch (error) {
        console.log(error)
      } finally {
        this.isSavingShares = false
        this.$emit("updateShares")
        this.close()
      }
    },
    close() {
      this.$emit("closeDialog")
    },
  },
}
